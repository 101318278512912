import type { NavItem } from "~/components/site/nav";
import { Nav } from "~/components/site/nav";

const navigation: NavItem[] = [
  { name: "Careers", href: "/careers", dotFeature: "careers-dot" },
  { name: "Security", href: "/security" },
  { name: "Legal", href: "/legal/terms" },
  { name: "Privacy", href: "/legal/privacy" },
];

export function Footer(): JSX.Element {
  return (
    <footer className="text-sm text-gray-500 dark:text-gray-400">
      <div className="container py-6 md:pb-[34px] flex flex-col md:flex-row gap-6 items-center">
        <p className="order-2 md:order-1 md:pr-6 md:border-r border-primary">
          © {new Date().getFullYear()} Brale Inc. NMLS ID #2376957
        </p>
        <div className="order-1 md:order-2 flex flex-wrap items-center space-x-6 gap-y-2 print:hidden">
          {navigation.map((n) => (
            <Nav className="nav hover" key={n.name} item={n} />
          ))}
        </div>
      </div>
    </footer>
  );
}
