import { IfFeatureEnabled } from "@growthbook/growthbook-react";
import { Link } from "@remix-run/react";

type SubNavItem = {
  name: string;
  href: string;
  external?: boolean;
};

export type NavItem = Readonly<{
  name: string;
  href: string;
  external?: boolean;
  dotFeature?: string;
  feature?: string;
  subItems?: SubNavItem[];
}>;

type Props = Readonly<{
  className: string;
  item: NavItem;
}>;

export function Nav({ className, item }: Props) {
  return item.feature ? (
    <IfFeatureEnabled feature={item.feature}>
      <Item className={className} item={item} />
    </IfFeatureEnabled>
  ) : (
    <Item className={className} item={item} />
  );
}

function Item({ className, item }: Props) {
  return (
    <Link className={className} prefetch="intent" to={item.href}>
      {item.dotFeature ? (
        <div className="flex flex-row justify-center items-center gap-x-1 sm:gap-x-2">
          {item.name}
          <IfFeatureEnabled feature={item.dotFeature}>
            <span className="relative inline-flex h-[26px] items-center justify-center rounded-full bg-secondary border border-primary text-sm px-2">
              5
              <div className="absolute -bottom-[1px] -right-[1px] rounded-full h-2 w-2 bg-purple-600" />
            </span>
          </IfFeatureEnabled>{" "}
        </div>
      ) : (
        item.name
      )}
    </Link>
  );
}
